


















































import { defineComponent } from "@vue/composition-api";
import { IHistoricalNoteElement } from "@monorepo/inventory/src/views/HistoricalNotesView/types/element";
import moment from "moment";

interface IHeader {
  width: number;
  value: string;
  text: string;
  tooltip?: string;
}

export default defineComponent({
  name: "HistoricalNotesTable",
  props: {
    historicalNotes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    headers(): IHeader[] {
      return [
        { width: 260, value: "guid", text: "Идентификатор", tooltip: "Идентификатор" },
        { width: 200, value: "title", text: "Наименование", tooltip: "Наименование" },
        { width: 100, value: "kind", text: "Признак", tooltip: "Признак" },
        { width: 80, value: "yearPeriod", text: "Период", tooltip: "Период" },
        { width: 120, value: "approvedState", text: "Статус утверждения", tooltip: "Статус утверждения" },
      ];
    },
    resultData(): (Omit<IHistoricalNoteElement, "yearPeriod" | "kind" | "approvedState"> & {
      kind: string;
      approvedState: string;
      yearPeriod: string;
    })[] {
      return [...((this.historicalNotes ?? []) as IHistoricalNoteElement[])].map((item) => ({
        ...item,
        yearPeriod: item.yearPeriod?.join("-") ?? "",
        kind: item.kind?.title ?? "",
        approvedState: item.approvedState?.title ?? "",
      }));
    },
  },
  methods: {
    handleOpen(item: IHistoricalNoteElement) {
      this.$router.push({
        path: "/inventory/historical-notes",
        query: {
          guid: item.guid?.toString() || "",
          isOpenModal: "1",
        },
      });
    },
  },
});
